import { createSlice } from '@reduxjs/toolkit'
import { CURRENT_PROJECT } from '../constant'
import { PROJECT_LIST } from '../constant'

/**
 * 默认加载的project
 * @type {Slice<{}, {setCurrentProject(*, *): void, clearCurrentProject(*, *): void}, string>}
 */
const currentProjectSlice = createSlice({
  name: 'currentProject',
  initialState: {
    value: localStorage.getItem(CURRENT_PROJECT) ? JSON.parse(localStorage.getItem(CURRENT_PROJECT) ?? '') : undefined,
    list: localStorage.getItem(PROJECT_LIST) ? JSON.parse(localStorage.getItem(PROJECT_LIST) ?? '') : []
  },
  reducers: {
    setCurrentProject (state, action) {
      state.value = action.payload
      localStorage.setItem(CURRENT_PROJECT, JSON.stringify(action.payload))
    },
    clearCurrentProject (state?: any) {
      state.value = undefined
      localStorage.removeItem(CURRENT_PROJECT)
    },
    setProjectList (state, action) {
      state.list = action.payload
      localStorage.setItem(PROJECT_LIST, JSON.stringify(action.payload))
    },
    clearProjectList (state, action) {
      state.list = undefined
      localStorage.removeItem(PROJECT_LIST)
    }
  }
})

export const { setCurrentProject, clearCurrentProject, setProjectList, clearProjectList } = currentProjectSlice.actions
export default currentProjectSlice.reducer
