export const TOKEN_NAME = 'token'
export const USERINFO_NAME = 'userinfo'
export const CURRENT_PROJECT = 'current_project'
export const PROJECT_LIST = 'project_list'


export const mainStructures = {
    LIGAND_DOCKED_NO_H: "ligandDockedNoH",
    LIGAND_DOCKED_WITH_H: "ligandDockedWithH",
    POS_LIGAND_DOCKED_NO_H: 'posLigandDockedNoH',
    POS_LIGAND_DOCKED_WITH_H: 'posLigandDockedWithH',
    TARGET_DOCKED_WITH_H: "targetDockedWithH",
    NEG_LIGAND_DOCKED_WITH_H: 'negLigandDockedWithH'
}
export const MAIN_LIGANDS = [
    mainStructures.LIGAND_DOCKED_NO_H,
    mainStructures.LIGAND_DOCKED_WITH_H,
    mainStructures.POS_LIGAND_DOCKED_NO_H,
    mainStructures.POS_LIGAND_DOCKED_WITH_H,
]
