import http from '../utils/http'

const PREFIX = 'molstar/client/'

const client = {
  /**
   * Get personal information
   */
  async getMine () {
    return await http.get(PREFIX + 'getMine', {})
  },
  /**
   * Update personal information
   */
  async updateMineInfo (params) {
    return await http.post(PREFIX + 'updateMineInfo', params)
  },
  /**
   * Forget password
   */
  async resetPwd (params) {
    return await http.post(PREFIX + 'resetPwd', params)
  },
  /**
   * Register
   */
  async register (params) {
    return await http.post(PREFIX + 'register', params)
  },
  /**
   * Email and SMS recovery
   */
  async emailSend (params) {
    return await http.post(PREFIX + 'emailSend', params)
  },
  /**
   * Reset password
   */
  async resetPassword (params) {
    return await http.put(PREFIX + 'resetPassword', params)
  }
}

export default client;