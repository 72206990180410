import axios, {AxiosRequestConfig} from 'axios';
import moment from "moment";
import Qs from 'qs';
import semver from 'semver';

import store from '../stores/index';
import {setToken} from "../stores/token";
import {setUserinfo} from "../stores/account";
import LoginApi from '../api/login';
import {encryptData, decryptData} from './encrypt';

const http: any = {};
var instance = axios.create({
    timeout: 500000,
    baseURL: process.env.REACT_APP_API_URL,
})

// Add a request interceptor
instance.interceptors.request.use(config => {
    return config
})

// Add a response interceptor
instance.interceptors.request.use(
  async config  => {
      let _config: AxiosRequestConfig = config
      try {
          // 在发送请求之前做某事，比如说 增加token
          if (_config.data) {
            const data:string = await encryptData(JSON.stringify(_config.data))
            _config.data = data
          }

          if (_config.params) {

            for (const paramsKey in _config.params) {
                if(_config.params[paramsKey] && paramsKey !== "userId"){
                    _config.params[paramsKey] = await encryptData(_config.params[paramsKey])
                }

            }
          }

          if(!_config.headers){
              _config.headers = {}
          }

          _config.headers['Content-Type'] = `application/json;charset=UTF-8`;
          queryRefreshToken(_config);
      } catch (e) {
          console.error(e)
      }

      return _config;
  },
  error => {
    console.log('ERROR Request', error);
    return Promise.reject(error);
  }
)

instance.interceptors.response.use(
    async response => {
        if (
            (
                process.env.REACT_APP_VERSION &&
                response.headers["x-version"]) && 
            semver.gt(response.headers['x-version'], process.env?.REACT_APP_VERSION)) { // if server version newer
            window.localStorage.setItem('version-update-needed', 'true'); // set version update item to refresh app later
        };

        if (!process.env.REACT_APP_VERSION) {
            window.localStorage.setItem('version-update-needed', 'true'); // set version update item to refresh the app later
        }

        // Decrypt incoming request
        if (response.data) {
            const data = await decryptData(response.data);
            response.data = JSON.parse(data);
        }
        return response;
    },
    error => {
        return Promise.reject(error);
    }
)

http.get = function (url, data, config: any = {}) {
    return new Promise((resolve, reject) => {
        const publicParams = {
            ts: Date.now()
        }

        config.params = {...publicParams, ...(data || {})};
        config.paramsSerializer = (params) => {
            return Qs.stringify(params, {arrayFormat: 'repeat'});
        };

        instance.get(url, config)
          .then(response => {
              handleSuccess(response, resolve);
          }).catch(e => {
            handleError(e, reject);
        })
    })
}


http.post = function (url, data, config: any = {}) {
    const account = store.getState().account.value;

    if (account) {
        data.userInfo = {
            "userId": account.userId
        }
    }
    
    return new Promise((resolve, reject) => {
        instance.post(url, data, config).then(response => {
            handleSuccess(response, resolve);
        }).catch(e => {
            handleError(e, reject)
        })
    })
};


http.put = function (url, options, config: any = {}) {
    return new Promise((resolve, reject) => {
        instance.put(url, options).then(response => {
            handleSuccess(response, resolve);
        }).catch(e => {
            handleError(e, reject);
        })
    })
};


http.delete = function (url, data, config: any = {}) {
    return new Promise((resolve, reject) => {

        const publicParams = {
            ts: Date.now()
        }

        config.params = {...publicParams, ...(data || {})};
        config.paramsSerializer = (params) => {
            return Qs.stringify(params, {arrayFormat: 'repeat'});
        };
        instance.delete(url, config).then(response => {
            handleSuccess(response, resolve);
        }).catch(e => {
            handleError(e, reject);
        })
    })
};

http.postDownload = function (url, data, config: any = {}) {
    return new Promise((resolve, reject) => {
        config.responseType = 'blob';
        instance.post(url, data, config).then(response => {
            handleSuccess(response, resolve);
        }).catch(e => {
            handleError(e, reject);
        })
    })
};

//get请求 目前仅支持post请求
http.getDownload = function (url, data, config: any = {}) {
    return new Promise((resolve, reject) => {
        const publicParams = {
            ts: Date.now()
        }

        config.params = {...publicParams, ...(data || {})};
        config.paramsSerializer = (params) => {
            return Qs.stringify(params, {arrayFormat: 'repeat'});
        };

        config.responseType = 'blob';
        instance.get(url, config)
          .then(response => {
              handleSuccess(response, resolve);
          }).catch(e => {
            handleError(e, reject);
        })
    })
};

//post 请求
http.postUpload = function (url, options) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_URL + url, options, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            // @ts-ignore
            processData: false
        }).then(response => {
            handleSuccess(response, resolve);
        }).catch(e => {
            handleError(e, reject);
        })
    })
};

function handleError(error, reject) {
    reject(error)
}

function handleSuccess(res, resolve, custom = {}) {
    if (res.data.isError) {
        // Not logged in
        if (res.data.code === 40000 || res.data.code === 40001
          || res.data.code === 40002 || res.data.code === 40003
          || res.data.code === 40005 || res.data.code === 40008) {
            // Modal.warning({
            //     title: 'Tips',
            //     content: 'Login expired, please login again',
            //     okText: 'Login',
            //     onOk() {
            //         store.dispatch(clearUserinfo())
            //         store.dispatch(clearToken())
            //         window.location.href = '/login'
            //     }
            // })
            return;
        }
        resolve(res.data);
        return;
    }

    if (res.data instanceof Blob) {
        resolve(res);
        return;
    }

    if (res.data instanceof ArrayBuffer) {
        resolve(res);
        return;
    }

    resolve(res.data);
}

async function queryRefreshToken(config) {
    let userInfo = store.getState().account.value;
    let token = store.getState().token.value;
    if (!token || !userInfo) {
        return config;
    }

    let refreshToken = userInfo.refreshToken;
    userInfo = JSON.parse(JSON.stringify(userInfo))
    if (!refreshToken || !userInfo.expiration) {
        config.headers.common['Authorization'] = `Bearer ${token}`;
        return config;
    }

    //差剩余5分钟内重新刷新一次
    //剩余分钟
    let syTime = (moment(userInfo.expiration).valueOf() - new Date().getTime()) / (1000 * 60);
    if (syTime > 5) {
        config.headers.common['Authorization'] = `Bearer ${token}`;
        return config;
    }

    const result = await axios.post(process.env.REACT_APP_API_URL + '/' + LoginApi.refreshToken(), {
        grantType: 'refresh_token',
        refreshToken: refreshToken
    });

    if (result.status === 200 && result.data.data && result.data.data.token) {
        userInfo.expiration = result.data.data.expiration;
        store.dispatch(setUserinfo(userInfo))
        store.dispatch(setToken(result.data.data.token))
        token = result.data.data.token
    }

    config.headers.common['Authorization'] = `Bearer ${token}`;
}


export default http;
