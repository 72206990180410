import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Popover, Tooltip } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

import '../index.scss';
import backImg from '@/assets/images/icon/back.svg'
import homeImg from '@/assets/images/icon/home.svg'

import store from '../../../stores';
import UserCenter from '../userCenter';
import { queryParse } from '../../../utils/util';


export const AuthenticatedNav = (props) => {
    const location = useLocation();
    const [userInfo, setUserInfo] = useState(store.getState().account.value);
    const [visible, setVisible] = useState(false);
  
    useEffect(() => {
      setUserInfo(store.getState().account.value);
    }, [location])
  
    // ? Does this need to be called?
    queryParse()
    const onOpenChange = bool => {
      setVisible(bool);
    }
    const propsData = {
      ...props,
      closePopoverHandler: () => {
        setVisible(false);
      }
    }
    const formatName = (name, type: any = undefined) => {
      if (!type) return name.split(',') || [];
      return type === 'first' ? name.split(',')[0].substr(0, 1).toUpperCase() : name.split(',')[1].substr(0, 1).toUpperCase();
    }
  
    return (
        <div style={{zIndex: 999, width: "100%", height: "120px", display: "flex", flexDirection: "column", position: "fixed", top: 0, left: 0 }}>
            <div className="header">
                <div className="header-layout">
                    <div className="b-logo">
                        <div className="left-nav">
                            <Link to="/home" className="name-logo">QuanMol Redefine</Link>
                        </div>
                        <div className="right-nav">
                            <Tooltip title="Back to my tasks" placement="right">
                                <Link to="/home" className="logo tasks">
                                    <img src={backImg} alt=""/>All tasks
                                </Link>
                            </Tooltip>
                            <Tooltip title="Get in touch!" placement="right">
                                <a className="logo email-box" href="mailto:feedback@quanmol.com">
                                    <img src={homeImg} style={{ width: "20px", height: "20px" }} alt=""/> 
                                        Send feedback
                                </a>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="b-user">

                        <Popover
                            open={visible}
                            onOpenChange={onOpenChange}
                            content={<UserCenter {...propsData} />}
                        >
                            <div className="b-user">
                                <div className="b-user-photo">
                                    {
                                        userInfo.avatar
                                        ? (
                                            <img src={userInfo.avatar} alt=""/>
                                            )
                                        : (
                                            <span>{formatName(userInfo.name, 'first') + '' + formatName(userInfo.name, 'last')}</span>
                                            )
                                    }
                                </div>
                                <div className="b-user-info">
                                    <p>{userInfo ? formatName(userInfo.name)[0] + '  ' + formatName(userInfo.name)[1] : ''}</p>
                                    <CaretDownOutlined onPointerOverCapture={undefined} onPointerMoveCapture={undefined}/>
                                </div>
                            </div>
                        </Popover>
                    </div>
                </div>
            </div>
         </div>
    );
}