import './index.scss'
import {
  InteractionOutlined,
  SettingOutlined,
  LogoutOutlined,
  CheckOutlined,
  AlignRightOutlined
} from '@ant-design/icons'
import { Modal } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useRef, useState } from 'react'
import Add from './components/add'
import store from '../../stores/index'
import { clearUserinfo } from '../../stores/account'
import { clearToken } from '../../stores/token'
import { setCurrentProject, clearCurrentProject, setProjectList } from '../../stores/currentProject'
import molstarApi from '../../api/molstar'

const UserCenter = (props) => {
  const project = store.getState().currentProject.value
  const userInfo = store.getState().account.value
  const [projects, setProjects] = useState(store.getState().currentProject.list)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate()

  const myLibrary = () => {
    navigate('/library')
  }
  const logOut = () => {
    Modal.confirm({
      title: 'confirm',
      content: 'Confirm to log out？',
      onOk () {
        store.dispatch(clearUserinfo())
        store.dispatch(clearToken())
        store.dispatch(clearCurrentProject())
        navigate('/login')
      }
    })
  }

  const getProjectList = async () => {
    const res = await molstarApi.listMyProject()
    if (!res.isSuccess) { return }
    const projects = res.data || []
    store.dispatch(setProjectList(projects))
    setProjects(projects)
  }

  const goSettings = () => {
    navigate('/settings')
  }

  const addRef: any = useRef(null)

  // 添加新项目
  const addProject = () => {
    props.closePopoverHandler && props.closePopoverHandler()
    setIsModalOpen(true);
    // addRef.current.showModal()
  }

  const closeModal = () => {
    setIsModalOpen(false);
  }
  
  // 选择项目
  const changeProject = (item, index) => {
    molstarApi.record(item.id).then(res => {
      if (res.isSuccess) {
        store.dispatch(setCurrentProject(item))
        setTimeout(() => {
          navigate(`/home?ts=${Date.now()}`)
        }, 300)
      }
    })
  }
  return (

            <div className="header-user-center">
                <div className="l-nav">
                    <div className="l-nav-name">
                        <InteractionOutlined style={{ fontSize: '18px' }} onPointerOverCapture={undefined} onPointerMoveCapture={undefined} />
                        <p>Switch projects</p>
                    </div>
                    <div className="b-sec-nav">
                        {projects.map((item, index) => {
                          return (
                                <div className="l-sec-nav" key={index} onClick={() => { changeProject(item, index) }}>
                                    <div className="nav-info">
                                        <p className="p-name">{item.title}</p>
                                        <p className="p-date">{item.lastModifiedTime}</p>
                                    </div>
                                    <CheckOutlined style={{ fontSize: '16px', display: item.id === project?.id ? 'block' : '' }} className="i-choose" onPointerOverCapture={undefined} onPointerMoveCapture={undefined} />
                                </div>
                          )
                        })}
                        <div className={`l-sec-nav l-sec-btn p-name ${userInfo.isAdmin ? '' : 'hide'}`} onClick={addProject}>
                            + Add new project
                        </div>
                    </div>
                </div>
                <div>
                    { isModalOpen ? <Add closeModal={closeModal}ref={addRef} getProjectList={getProjectList}/> : <div></div> }
                </div>

                <div className="l-nav">
                    <div className="l-nav-name">
                        <SettingOutlined style={{ fontSize: '18px' }} onPointerOverCapture={undefined} onPointerMoveCapture={undefined} />
                        <p onClick={goSettings}>Account settings</p>
                    </div>
                </div>

                <div className="l-nav">
                    <div className="l-nav-name">
                        <AlignRightOutlined style={{ fontSize: '18px' }} onPointerOverCapture={undefined} onPointerMoveCapture={undefined}/>
                        <p onClick={myLibrary}>My library </p>
                    </div>
                </div>
                <div className="l-nav">
                    <div className="l-nav-name">
                        <LogoutOutlined style={{ fontSize: '18px' }} onPointerOverCapture={undefined} onPointerMoveCapture={undefined} />
                        <p onClick={logOut}>Log out</p>
                    </div>
                </div>
            </div>

  )
}
export default UserCenter
