import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_VIEW } from "../views/main/constants";


const initialState: any = {
    defaultRendered: false,
    hbondRendered: false,
    shapeRendered: false,
    electrostaticRendered: false,
    ligandData: [],
    molstarInitialized: false,
    task: {},
    activeResidues: {},
    selectedAtomLabel: {
        tagLabel: "",
        structureLabel: ""
    },
    buttonType: 'default',
    currentView: DEFAULT_VIEW,
    mainStructure: '',
    fixPdbError: false,
    fixPdbPromise: ''
}


const taskSlice = createSlice({
    name: 'taskState',
    initialState,
    reducers: {
        updateFixPdbError: (state: any, action: any) => {
            state.fixPdbError = action.payload;
        },
        updateFixPdbPromise: (state: any, action: any) => {
            state.fixPdbPromise = action.payload;
        },
        updateMainStructure: (state: any, action: any) => {
            state.mainStructure = action.payload;
        },
        updateDefaultPreset: (state: any, action: any) => {
            state.defaultRendered = action.payload;
        },
        updateElectrostaticPreset: (state: any, action: any) => {
            state.electrostaticRendered = action.payload;
        },
        updateHydrogenbondPreset: (state: any, action: any) => {
            state.hbondRendered = action.payload;
        },
        updateShapePreset: (state: any, action: any) => {
            state.shapeRendered = action.payload;
        },
        updateLigandData: (state, action: any) => {
            state.ligandData = action.payload;
        },
        setMolstarInitialized: (state: any, action: any) => {
            state.molstarInitialized = action.payload;
        },
        updateTask: (state: any, action: any) => {
            state.task = action.payload;
        },
        updateButtonType: (state: any, action: any) => {
            state.buttonType = action.payload;
        },
        updateSelectedAtomLabel: (state: any, action: any) => {
            state.selectedAtomLabel = action.payload;
        },
        updateActiveResidues: (state: any, action: any) => {
            state.activeResidues[action.payload] = action.payload;
        },
        resetActiveResidues: (state: any) => {
            state.activeResidues = {};
        },
        deleteActiveResidue: (state: any, action: any) => {
            delete state.activeResidues[action.payload];
        },
        updateCurrentView: (state: any, action: any) => {
            state.currentView = action.payload;
        },
        resetRender: (state: any) => {
            state.defaultRendered = false;
            state.hbondRendered = false;
            state.shapeRendered = false;
            state.electrostaticRendered = false;
        },
    }
});


export const {
    updateFixPdbError,
    updateFixPdbPromise,
    updateDefaultPreset,
    updateElectrostaticPreset,
    updateHydrogenbondPreset,
    updateShapePreset,
    updateLigandData,
    setMolstarInitialized,
    updateTask,
    updateButtonType,
    updateSelectedAtomLabel,
    updateActiveResidues,
    deleteActiveResidue,
    updateCurrentView,
    resetActiveResidues,
    resetRender,
    updateMainStructure
} = taskSlice.actions;

export default taskSlice.reducer;
