import http from '../utils/http'

const PREFIX = 'molstar/project/'

interface SaveParams {
  title: string;
  isPick?: boolean
}

const molstar = {
  /**
   * Query project list
   */
  async listMyProject() {
    return await http.get(PREFIX + 'listMyProject', {})
  },
  /**
   * Query project
   * @param id
   */
  async project(id: string) {
    return await http.get(PREFIX + `${id}`, {})
  },
  /**
   * Add project
   * @param
   */
  async save(params: SaveParams) {
    return await http.post(PREFIX + 'save', params)
  },
  /**
   * Project record
   * @id
   */
  async record(id: string) {
    return await http.get(PREFIX + `record`, {projectId: id})
  }
}

export default molstar
