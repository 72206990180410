import { Suspense } from 'react'
import { Spin } from 'antd'

/**
 * @description 路由懒加载
 * @param {Element} Comp 需要访问的组件
 * @returns element
 */
const lazyLoad = (Comp) => {
  return (
    <Suspense
		fallback={
			<div style={{
			  position: 'fixed',
			  top: '0',
			  left: '0',
			  width: '100%',
			  height: '100%',
			  display: 'flex',
			  alignItems: 'center',
			  justifyContent: 'center'
			}}>
				<Spin
					size="large"
				/>
			</div>
		}
	>
	<Comp />
	</Suspense>
  )
}

export default lazyLoad
