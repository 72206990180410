import React from 'react'
import LayoutRouteView from '../../layout/index'
import lazyLoad from '../utils/lazyLoad'

const assemblyRouter = [
  {
    element: <LayoutRouteView/>,
    meta: {
      title: 'molstar'
    },
    children: [
      {
        path: '/home',
        element: lazyLoad(React.lazy(() => import('../../views/home/index'))),
        meta: {
          requiresAuth: true,
          title: 'home',
          key: 'home'
        }
      },
      {
        path: '/createTask',
        element: lazyLoad(React.lazy(() => import('../../views/createTask/index'))),
        meta: {
          requiresAuth: true,
          title: 'create new task',
          key: 'createTask'
        }
      },
      {
        path: '/selectLigand',
        element: lazyLoad(React.lazy(() => import('../../views/createTask/components/selectLigand/index'))),
        meta: {
          requiresAuth: true,
          title: 'Select Ligand',
          key: 'selectLigand'
        }
      },
      {
        path: '/pocketSelection',
        element: lazyLoad(React.lazy(() => import('../../views/pocketSelection/index'))),
        meta: {
          requiresAuth: true,
          title: 'Pocket Selection',
          key: 'pocketSelection'
        }
      },
      {
        path: '/overView',
        element: lazyLoad(React.lazy(() => import('../../views/overView/index'))),
        meta: {
          requiresAuth: true,
          title: 'over view',
          key: 'overView'
        }
      },
      {
        path: '/settings',
        element: lazyLoad(React.lazy(() => import('../../views/settings/index'))),
        meta: {
          requiresAuth: true,
          title: 'settings',
          key: 'settings'
        }
      },
      {
        path: '/main',
        element: lazyLoad(React.lazy(() => import('../../views/main/index'))),
        meta: {
          requiresAuth: true,
          title: 'main',
          key: 'main'
        }
      },
      {
        path: '/library',
        element: lazyLoad(React.lazy(() => import('../../views/library/index'))),
        meta: {
          requiresAuth: true,
          title: 'library',
          key: 'library'
        }
      }
    ]
  }
]

export default assemblyRouter
