import { useState, useRef } from 'react'
import { Button, Modal, Form, Input, message } from 'antd'
import molstarApi from '../../../api/molstar'

const Add = (props: any) => {
  const ref = useRef(null);

  const handleCancel = () => {
    setParams({ title: '' })
    props.closeModal();
  }
  const [params, setParams] = useState({
    title: ''
  })
  const handleChange = e => {
    const { value, name } = e.target
    setParams(preData => {
      return {
        ...preData,
        [name]: value
      }
    })
  }

  const submitProject = () => {
    if (!params.title) {
      message.error('Project name cannot be empty')
    } else {
      molstarApi.save(params).then((res) => {
        if (!res.isSuccess) { return message.error(res.msg) }
        message.info('Successfully added')
        props.getProjectList()
      })
      props.closeModal();
    }
  }

  function handleKeyUp(event) {
    // User pressed enter
    if (event.keyCode === 13) submitProject();
  }

  const preventBubble = (e) => {
    e.preventDefault()
  }
  return (
        <>
            <Modal title="Create a new project" centered
                   destroyOnClose={true}
                   open={true} footer={null}
                   width={800} onCancel={handleCancel}>

                <Form
                    name="basic"
                    labelCol={{
                      span: 8
                    }}
                    wrapperCol={{
                      span: 13
                    }}
                    initialValues={{
                      remember: true
                    }}
                    ref={ref}
                    onKeyUp={handleKeyUp}
                    tabIndex={0}
                >
                    <Form.Item
                        label="Name the project"
                        name="title"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter the project name!'
                          }
                        ]}
                    >
                        <div style={{ display: 'none' }}>{params.title}</div>
                        <Input name='title' value={params.title} onChange={handleChange} onPressEnter={(e)=>preventBubble(e)}/>
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                          offset: 8,
                          span: 16
                        }}
                    >
                        <Button htmlType="submit" onClick={handleCancel} style={{ marginRight: '30px' }}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" onClick={submitProject}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
  )
}

export default Add
