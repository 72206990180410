import { ProteinPrepareVo, StepType, TaskType, UploadContent, UploadContentType, UserInfo } from '../@types/types';
import http from '../utils/http'

const PREFIX = 'molstar/task/'

interface ProteinContent {
  content?: UploadContent;
  proteinFullSeq?: string;
}

interface LigandContent {
  content?: UploadContent;
  smiles?: string;
  ligandId?: string;
}

interface TaskUpdateParams {
  id: string; // task id
  projectId: string;
  groupId?: string;
  taskName: string;
  taskType: TaskType;
  protein: ProteinContent;
  liganda: LigandContent;
  ligangb: LigandContent;
  isGreater: boolean;
  targetId?: string;
  kmsKey?: string;
  userInfo?: UserInfo;
  step?: StepType;
  currentStep?: StepType;
  additionalLigands?: string;
  molModeA?: number;
  molModeB?: number;
  molRef?: string;
  parsePdbResults?: ProteinPrepareVo;
}

interface TaskLigandUpdateParams {
  taskId: string;
  ligandId?: string;
  newLigandId?: string;
  smiles?: string;
  isLigandStrength?: Boolean;
  userInfo?: UserInfo;
}

interface PrepareLigandParams {
  contentType: UploadContentType;
  content: string;
  taskId: string;
  ligandId?: string;
  projectId?: string;
  moduleType?: string;
  userInfo?: UserInfo;
}

interface SelectTaskPocketParams {
  taskId: string;
  pocketId?: string;
  sourcePocketId?: number;
  userInfo?: UserInfo;
  task?: TaskUpdateParams;
}

interface UpdateTaskShareParams {
  taskId: string;
  isShared?: Boolean;
}

interface UpdateNameParams {
  id: string;
  taskName: string;
}

interface UpdateGroupOpenParams {
  groupId: string;
  closed: Boolean;
}

interface FixPdbParams {
  taskId: string;
  targetId?: string;
  projectId?: string;
  proteinFullSeq: string;
  userInfo?: UserInfo;
}

interface SaveCoverUrlParams {
  taskId?: string;
  url?: string;
}

interface ParsePdbParams {
  id: string; // task id
  projectId?: string;
  targetId: string;
  protein: UploadContent;
  userInfo?: UserInfo;
  filterLigandId?: string;
}

const taskApi = {


  async updateTask(params: any): Promise<any> {
    console.log("INSIDE OF UPDATE TASK", params);
    return http.post(PREFIX + "updateTask", params);
  },

  async createTask(params: any): Promise<any> {
    return http.post(PREFIX + 'createTask', params);
  },

  /**
   * Add new task
   */
  save(params: TaskUpdateParams) {
    return http.post(PREFIX + 'save', params)
  },
  /**
   * Copy a task.
   */
  async copyTask(oldTaskId: string, newTaskId: string) {
    console.log("oldTaskId", oldTaskId);
    console.log("newTaskId", newTaskId);

    return http.post(PREFIX + 'copyTask', {
      oldTaskId, 
      newTaskId}
    );
  },
  /**
   * Update task information
   */
  // NOTICE: This API seems not implemented in the backend.
  update(params: TaskUpdateParams) {
    return http.post(PREFIX + 'update', params)
  },
  /**
   * Switch suggestions or modify molecule structure
   */
  changeLigand(params: TaskLigandUpdateParams) {
    return http.post(PREFIX + 'changeLigand', params)
  },
  /**
   * Switch strength
   */
  changeGreater(params: TaskLigandUpdateParams) {
    return http.post(PREFIX + 'changeGreater', params)
  },
  /**
   * Get pockets under a task
   */
  findPockets(taskId: string) {
    return http.post(PREFIX + `findPockets`, { taskId })
  },
  /**
   * Delete task
   */
  delete(id: string) {
    return http.get(PREFIX + `delete`,{id})
  },
  /**
   * Display smiles image
   */
  getSmileUrl(smiles: string) {
    return http.post(PREFIX + `getSmileUsl`, {smiles: smiles})
  },
  /**
   * Prepare molecules
   */
  prepareLigand(params: PrepareLigandParams) {
    return http.post(PREFIX + `prepareLigand`, params)
  },
  /**
   * Fix molecule
   */
  fixLigand(params: TaskLigandUpdateParams) {
    return http.post(PREFIX + `fixLigand`, params)
  },
  /**
   * Get ligands under a task
   */
  listTaskLigand(id: string) {
    return http.get(PREFIX + `listTaskLigand`, {taskId: id})
  },
  /**
   * Select pocket
   */
  selectPocket(params: SelectTaskPocketParams) {
    return http.post(PREFIX + 'selectPocket', params)
  },
  /**
   * Move task to a group
   */
  moveToGroup(groupId: string, taskId: string) {
    return http.get(PREFIX + `moveToGroup`, {taskId, groupId})
  },
  /**
   * Update task sharing status
   */
  updateTaskShare(params: UpdateTaskShareParams) {
    return http.post(PREFIX + 'updateTaskShare', params)
  },
  /**
   * Update task name
   */
  updateName(params: UpdateNameParams) {
    return http.post(PREFIX + 'updateName', params)
  },
  /**
   * Record task open and close
   */
  updateGroupOpen(params: UpdateGroupOpenParams) {
    return http.post(PREFIX + 'updateGroupOpen', params)
  },
  /**
   * Start generating Predicted potency and Improvement suggestions
   */
  start(taskId: string, userId: string) {
    return http.get(PREFIX + `start`, {taskId, userId})
  },
  /**
   * Get task details
   */
  getTask(id: string) {
    return http.get(PREFIX + `getTask`, {taskId: id})
  },

  /**
   * Fix missing residues
   */
  async fixPdb(params: FixPdbParams) {
    return await http.post(PREFIX + 'fixPdb', params)
  },
  /**
   * Select ligand
   */
  // NOTICE: This API seems not implemented in the backend.
  selectLigand(params: any) {
    return http.post(PREFIX + 'selectLigand', params)
  },
  /**
   * Overview page
   */
  overView(id: string) {
    return http.get(PREFIX + `overView`, {taskId: id})
  },
  /**
   * Overview page
   */
  saveCoverUrl(params: SaveCoverUrlParams) {
    return http.post(PREFIX + 'saveCoverUrl', params)
  },
  /**
   * Overview page
   */
  async updateLigand(params: TaskLigandUpdateParams) {
    return http.post(PREFIX + 'updateLigand', params)
  },
  /**
   * Parse pdb data
   */
  async parsePdb(params: ParsePdbParams) {
    return await http.post(PREFIX + 'parsePdb', params)
  },
  /**
   * Convert image to SMILES.
   */
  async image2smiles(taskId: string, url: string, userId: string) {
    return await http.get(PREFIX + 'image2smiles', {taskId, url, userId})
  },

  /**
   * Retrieve presigned url for given s3 path
   */
  async getPresignedUrl(bucketName: string, key: string) {
    return await http.get(PREFIX + 'getPresignedUrl', { bucketName, key });
  }
}

export default taskApi
