import { createSlice } from '@reduxjs/toolkit'
import { USERINFO_NAME } from '../constant'

const userinfoSlice = createSlice({
  name: 'userinfo',
  initialState: {
    value: localStorage.getItem(USERINFO_NAME) ? JSON.parse(localStorage.getItem(USERINFO_NAME) ?? '') : undefined
  },
  reducers: {
    setUserinfo (state, action) {
      state.value = action.payload
      localStorage.setItem(USERINFO_NAME, JSON.stringify(action.payload))
    },
    clearUserinfo (state?: any) {
      state.value = undefined
      localStorage.removeItem(USERINFO_NAME)
    }
  }
})
export const { setUserinfo, clearUserinfo } = userinfoSlice.actions
export default userinfoSlice.reducer
