import { Navigate, useRoutes } from 'react-router-dom';
import Login from '../views/login';
import assemblyRouter from './modules/authRoutes';

export const rootRouter = [
  {
    path: '/',
    element: <Navigate to="/home" />
  },
  ...assemblyRouter,
  {
    path: '/login',
    element: <Login />,
    meta: {
      requiresAuth: false,
      title: 'Login',
      key: 'login'
    }
  },
  {
    path: '*',
    element: <Navigate to="/login" />
  }
]
const Routers = () => {
  const routes = useRoutes(rootRouter)
  return routes
}

export default Routers
