import {createSlice} from '@reduxjs/toolkit'

const encryptSlice = createSlice({
  name: 'encrypt',
  initialState: {
    value: ''
  },
  reducers: {
    setEncrypt(state, action) {
      state.value = action.payload
    },
    clearEncrypt(state?: any) {
      state.value = ''
    }
  }
})
export const {setEncrypt, clearEncrypt} = encryptSlice.actions
export default encryptSlice.reducer
