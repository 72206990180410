import { useState, useEffect } from 'react';
import './index.scss';
import store from '../../stores/index';
import { useLocation } from 'react-router-dom';
import { queryParse } from '../../utils/util';
import { UnAuthenticatedNav } from './components/UnAuthenticatedNav';
import { AuthenticatedNav } from './components/AuthenticatedNav';

export const Nav = (props) => {
  const location = useLocation();
  const [userInfo, setUserInfo] = useState(store.getState().account.value);

  useEffect(() => {
    setUserInfo(store.getState().account.value);
  }, [location])

  // ? Does this need to be called?
  queryParse();

  return ( (userInfo as any) ? <AuthenticatedNav /> : <UnAuthenticatedNav /> )
}
