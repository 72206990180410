import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { message } from 'antd'
import './index.scss'
import bannerBg from '../../assets/images/bg-banner.png'
import LoginApi from '../../api/login'
import clientApi from '../../api/client'
import projectUserApi from '../../api/projectUser'
import store from '../../stores/index'
import { setUserinfo } from '../../stores/account'
import { setToken } from '../../stores/token'
import { setCurrentProject } from '../../stores/currentProject'

const Login = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const url = location.search.split('?key=')
  const isRegister = url.length !== 1
  const resetUrl = location.search.split('?resetType=')[1] || ''
  const resetData = {
    resetType: resetUrl.split('&userKey=')[0] || '',
    userKey: resetUrl.split('&userKey=')[1] || ''
  }
  const [data, setData] = useState({
    account: '', password: ''
  })
  const [registerForm, setRegisterForm] = useState<any>({})
  const [forgetForm, setForgetForm] = useState({
    email: ''
  })
  const [resetForm, setResetForm] = useState({
    password: '',
    confirmPassword: ''
  })

  useEffect(() => {
    if (isRegister) {
      emailVerification()
    }
    // eslint-disable-next-line
  }, [])

  // 验证邮箱验证码
  const emailVerification = () => {
    projectUserApi.eamilVerification(url[1]).then(res => {
      if (res.isSuccess) {
        const data = {
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          email: res.data.email,
          projectId: res.data.projectId,
          password: '',
          confirmPassword: ''
        }
        setRegisterForm({ ...data })
      }
    })
  }

  const handleChange = (e, type) => {
    const { value, name } = e.target
    switch (type) {
      case 'login':
        setData(preData => {
          return {
            ...preData,
            [name]: value
          }
        })
        break
      case 'register':
        setRegisterForm(preData => {
          return {
            ...preData,
            [name]: value
          }
        })
        break
      case 'forget':
        setForgetForm(preData => {
          return {
            ...preData,
            [name]: value
          }
        })
        break
      case 'reset':
        setResetForm(preData => {
          return {
            ...preData,
            [name]: value
          }
        })
        break
    }
  }

  // 登录
  const onLogin = () => {
    const params = {
      ...data
    }

    if (params.account === '' || params.password === '') {
      message.error('Email and password cannot be empty')
      return
    }
    const emailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
    if (!emailReg.test(params.account)) {
      message.error('Please enter the correct email format')
      return
    }
    LoginApi.login(params).then((res) => {
      if (!res.isSuccess) {
        return message.error(res.msg)
      }
      const userinfo = JSON.parse(JSON.stringify(res.data))
      userinfo.isAdmin = res.data.data.isAdmin
      delete userinfo.token
      const project = {
        id: userinfo.data.projectId
      }
      store.dispatch(setCurrentProject(project))
      store.dispatch(setUserinfo(userinfo))
      store.dispatch(setToken(res.data.token))
      setTimeout(() => {
        navigate('/home')
      }, 100)
      message.info('Login succeeded')
    })
  }

  // 跳转忘记密码页面
  const goForgot = () => {
    navigate('/login?resetType=forget&userKey=1')
  }

  // 注册
  const register = () => {
    const params: any = registerForm
    if (params.firstName === '' || params.lastName === '') {
      message.error('Last name and first name cannot be empty')
      return
    }
    if (params.password === '') {
      message.error('Password cannot be empty')
      return
    }
    if (params.password !== params.confirmPassword) {
      message.error('Inconsistent passwords')
      return
    }
    clientApi.register(params).then(res => {
      if (res.isSuccess) {
        message.info('Successfully registered')
        navigate('/login')
      }
    })
  }

  // 忘记密码
  const forgotPassword = () => {
    const params: any = forgetForm
    params.type = 'FORGET'
    clientApi.emailSend(params).then(res => {
      if (res.isSuccess) {
        message.info('Sending succeeded')
        navigate('/login')
      } else {
        message.error(res.msg)
      }
    })
  }

  // 重置密码
  const resetPassword = () => {
    const params: any = resetForm
    params.userKey = resetData.userKey
    clientApi.resetPassword(params).then(res => {
      if (res.isSuccess) {
        message.info('Sending succeeded')
        navigate('/login')
      } else {
        message.error(res.msg)
      }
    })
  }

  const enterLogin = (e) => {
    if (e.keyCode === 13) {
      onLogin()
    }
  }

  const toLoginHandler = () => {
    navigate('/login')
  }

  return (
        <div className="p-login">
            <div className="form-box">
                <div className="banner-box">
                    <img src={bannerBg} alt=""/>
                </div>
                <div>
                    {/* login */}
                    <div className={`form ${(!resetData.resetType && !isRegister) ? '' : 'none'}`}>
                        <div className="welcome">Welcome aboard</div>
                        <div className="row f-row">
                            <div className="label">Email</div>
                            <input type="text" name='account'
                                   placeholder='Please enter your email'
                                   value={data.account} className="form-input"
                                   onChange={(e) => handleChange(e, 'login')}/>
                        </div>

                        <div className="row s-row">
                            <div className="label">Password</div>
                            <input type="password" name='password'
                                   placeholder='Please enter your password'
                                   value={data.password} className="form-input"
                                   onKeyUp={(e) => enterLogin(e)}
                                   onChange={(e) => handleChange(e, 'login')}/>
                        </div>

                        <div className="row t-row">
                            <div className="button" onClick={onLogin}>Login</div>
                        </div>
                        <div className='forgot' onClick={goForgot}>Forgot password?</div>
                    </div>
                    {/* 注册 */}
                    <div className={`register-form ${(!resetData.resetType && isRegister) ? '' : 'none'}`}>
                        <div className="welcome">Registration</div>
                        <div className="row f-row">
                            <input type="text" name='firstName'
                                   placeholder='First name' value={registerForm.firstName}
                                   className="form-input" onChange={(e) => handleChange(e, 'register')}/>
                        </div>

                        <div className="row f-row">
                            <input type="text" name='lastName'
                                   placeholder='Last name' value={registerForm.lastName}
                                   className="form-input" onChange={(e) => handleChange(e, 'register')}/>
                        </div>

                        <div className="row f-row">
                            <input type="text" name='email'
                                   disabled={true}
                                   placeholder='E-mail address' value={registerForm.email}
                                   className="form-input" onChange={(e) => handleChange(e, 'register')}/>
                        </div>

                        <div className="row f-row">
                            <input type="password" name='password'
                                   placeholder='Password' value={registerForm.password}
                                   className="form-input" onChange={(e) => handleChange(e, 'register')}/>
                        </div>

                        <div className="row f-row">
                            <input type="password" name='confirmPassword'
                                   placeholder='Confirm password' value={registerForm.confirmPassword }
                                   className="form-input" onChange={(e) => handleChange(e, 'register')}/>
                        </div>

                        <div className="row t-row">
                            <div className="button" onClick={register}>create account</div>
                        </div>
                        <div className='forgot' onClick={toLoginHandler}>To login?</div>

                    </div>
                    {/* 忘记密码 */}
                    <div className={`register-form ${(resetData.resetType === 'forget' && !isRegister) ? '' : 'none'}`}>
                        <div className="welcome">Forget the password</div>
                        <p></p>
                        <div className="row f-row">
                            <input type="text" name='email'
                                   placeholder='E-mail address' value={forgetForm.email}
                                   className="form-input" onChange={(e) => handleChange(e, 'forget')}/>
                        </div>
                        <div className="row t-row">
                            <div className="button" onClick={forgotPassword}>Send Email</div>
                        </div>
                        <div className='forgot' onClick={toLoginHandler}>To login?</div>
                    </div>
                    {/* 重置密码 */}
                    <div className={`register-form ${(resetData.resetType === 'reset' && !isRegister) ? '' : 'none'}`}>
                        <div className="welcome">Reset the password</div>
                        <div className="row f-row">
                            <input type="password" name='password'
                                   placeholder='Password' value={resetForm.password}
                                   className="form-input" onChange={(e) => handleChange(e, 'reset')}/>
                        </div>
                        <div className="row f-row">
                            <input type="password" name='confirmPassword'
                                   placeholder='Confirm Password' value={resetForm.confirmPassword}
                                   className="form-input" onChange={(e) => handleChange(e, 'reset')}/>
                        </div>

                        <div className="row t-row">
                            <div className="button" onClick={resetPassword}>Confirm the reset</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Login
