import http from '../utils/http'
import { UserInfo } from '../@types/types';

const PREFIX = 'molstar/projectUser/'

interface InviteUserParams {
  userInfo?: UserInfo;
  projectId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

interface updateIsEnterParams {
  id: string;
  isEnter?: boolean;
}

const projectUser = {
  /**
   * Get members in the project
   */
  async listGroup(id: string) {
    return await http.get(PREFIX + `listProjectUser`, {projectId: id})
  },
  /**
   * Invite People
   */
  async inviteUser(params: InviteUserParams) {
    return await http.post(PREFIX + 'inviteUser', params)
  },
  /**
   * Send Error Email
   */
  async sendErrorEmail(taskId: string, errorCode: number) {
    return await http.post(PREFIX + 'sendErrorEmail', { taskId, errorCode });
  },
  /**
   * Are you the person in charge of the project?
   */
  async isCharge(id: string) {
    return await http.get(PREFIX + `isCharge`, {projectId: id})
  },
  /**
   * Re-invite People
   */
  async reInviteUser(id: string) {
    return await http.get(PREFIX + `reInviteUser`, {projectUserId: id})
  },
  /**
   * Freeze Personnel
   */
  async updateIsEnter(params: updateIsEnterParams) {
    return await http.post(PREFIX + 'updateIsEnter', params)
  },
  /**
   * Verify Email Verification Code
   */
  async eamilVerification(key: string) {
    return await http.get(PREFIX + `emailVerification`, {key})
  },
  
  /**
   * Send email that user wants more invitations
   */
  async requestMoreEmail() {
    return await http.post(PREFIX + "requestMoreEmail", {});
  }
}

export default projectUser;