import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import * as amplitude from "@amplitude/analytics-browser";

import Routers from './routers';
import AuthRouter from './routers/utils/authRouter';
import './App.css';

const basename = process.env.REACT_APP_BASE_URL;
if (process.env.REACT_APP_BASE_ENV === 'prod') amplitude.init(process.env.REACT_APP_AMPLITUDE_API_TOKEN as string);


function App () {

  return (
    <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#043156'
          }
        }}
    >
        <BrowserRouter basename={basename}>
            <AuthRouter>
                <Routers />
            </AuthRouter>
        </BrowserRouter>
    </ConfigProvider>
  )
}

export default App
