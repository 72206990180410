import { v4 as uuidv4 } from 'uuid';

import taskApi from '../api/task';

/**
 * @description 递归查询对应的路由
 * @param {String} path 当前访问地址
 * @param {Array} routes 路由列表
 * @returns array
 */
export const searchRoute = (path, routes: any = []) => {
  let result = {}
  for (const item of routes) {
    if (item.path === path) return item
    if (item.children) {
      const res = searchRoute(path, item.children)
      if (Object.keys(res).length) result = res
    }
  }
  return result
}

/**
 * @description: 从地址栏获取url传递参数
 * @returns {{}} 返回参数对象
 */
export const queryParse = () => {
  const query = window.location.search.substring(1)
  const arr = query.split('&')
  const ret = {}
  arr.forEach(item => {
    const temp = item.split('=')
    ret[temp[0]] = temp[1]
  })
  return ret
}

/**
 * @description: 从地址栏获取url传递参数
 * @returns {{}} 返回参数对象
 */
export const createId = () => {
  return uuidv4().replaceAll('-','')
}


export const getPresignedUrl = async (path: string): Promise<string> => {
  
  if (!path) return '';
  const removedSlashes = path.replace("s3://", "");
  const bucketName = removedSlashes.substring(0, removedSlashes.indexOf("/"));
  const key = removedSlashes.replace(`${bucketName}/`, "");
  const result = await taskApi.getPresignedUrl(bucketName, key);

  return result.data;

}




