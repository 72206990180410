import { Outlet } from 'react-router-dom'
import { Nav } from './nav'
import './index.scss'

const LayoutRouteView = (props) => {
  return (
        <div className='layout'>
            <Nav />
            <div className='nav-placeholder'></div>
            <div className="view-content">
                <div className="view_main">
                    <Outlet></Outlet>
                </div>
            </div>
        </div>
  )
}

export default LayoutRouteView
