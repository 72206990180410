import { Link } from 'react-router-dom';

import '../index.scss';

export const UnAuthenticatedNav = () => {
    return (
        <div className="header">
            <div className="header-layout">
                <div className="b-logo">
                    <Link to="/home" className="logo">QuanMol Redefine</Link>
                </div>
                <div className="b-user">
                    <Link to="/login" className="login">Login</Link>
                </div>
            </div>
        </div>
    )
}