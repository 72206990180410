import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
    parsePdbResults: {}
}

const createTaskSlice = createSlice({
    name: 'createTaskState',
    initialState,
    reducers: {
        updateParsePdbResults: (state: any, action: any) => {
            state.parsePdbResults = action.payload;
        }
    }
});

export const {
    updateParsePdbResults
} = createTaskSlice.actions;

export default createTaskSlice.reducer;