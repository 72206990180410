import http from '../utils/http'

const PREFIX = 'oauth/anno/'

interface LoginParams {
  key?: string;
  code?: string;
  account?: string;
  password?: string;
  grantType?: 'password' | 'captcha' | 'refresh_token';
  refreshToken?: string;
}

const login = {
  /**
   * Login
   * @param params
   */
  async login(params: LoginParams) {
    params.grantType = 'password'
    return await http.post(PREFIX + 'login', params)
  },
  /**
   * Get encryption key
   */
  getEncryptKey() {
    return PREFIX + 'getEncryptKey'
  },
  /**
   * Get server public key
   */
  getServerPublicKey() {
    return PREFIX + 'getServerPublicKey'
  },
  /**
   * Refresh token
   */
  refreshToken() {
    return PREFIX + 'login'
  }
}

export default login