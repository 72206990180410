import {JSEncrypt} from 'encryptlong'
import {v4 as uuidv4} from 'uuid';
import axios from "axios";
import store from "../stores";
import LoginApi from "../api/login";
import {setEncrypt} from "../stores/encrypt";

const CryptoJS = require('crypto-js')

export const getEncrypt = async (): Promise<object> => {
  const key: string = uuidv4();
  const serverPublicKeyResult = await axios.get(process.env.REACT_APP_API_URL + '/' + LoginApi.getServerPublicKey() + '?key=' + key);

  const serverEncrypt = new JSEncrypt();
  serverEncrypt.setPublicKey(serverPublicKeyResult.data.data);
  const cliEncrypt = new JSEncrypt();
  const params = {
    key,
    cliPublicKey: serverEncrypt.encryptLong(cliEncrypt.getPublicKeyB64())
  }

  const encryptKeyResult = await axios.post(process.env.REACT_APP_API_URL + '/' + LoginApi.getEncryptKey(), params);
  const result: object = {
    private_key: cliEncrypt.getPrivateKeyB64(),
    encrypt_key: encryptKeyResult.data.data
  }


  return result
}

export const encryptData = async (data: string): Promise<string> => {
  var encryptKey:string = store.getState().encrypt.value,
    encrypt: any = Object.create(null);
  if (!encryptKey) {
    encrypt = await getEncrypt();
    store.dispatch(setEncrypt(JSON.stringify(encrypt)))
  } else {
    encrypt = JSON.parse(encryptKey)
  }

  const serverEncrypt: any = new JSEncrypt();
  serverEncrypt.setPrivateKey(encrypt.private_key);
  let key: any = serverEncrypt.decryptLong(encrypt.encrypt_key)
  key = CryptoJS.enc.Hex.parse(key)

  let srcs: any = CryptoJS.enc.Utf8.parse(data);
  let encrypted: any = CryptoJS.AES.encrypt(srcs, key, {
    mode : CryptoJS.mode.ECB,
    padding : CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
}

export const decryptData = async (data: string) => {
  data = (data + '').replace(/\n*$/g, '').replace(/\n/g, '')
  let encryptKey: string = store.getState().encrypt.value,
    encrypt: any = Object.create(null);
  if (!encryptKey) {
    encrypt = await getEncrypt();
    store.dispatch(setEncrypt(JSON.stringify(encrypt)))
  } else {
    encrypt = JSON.parse(encryptKey)
  }

  const serverEncrypt: any = new JSEncrypt();
  serverEncrypt.setPrivateKey(encrypt.private_key);
  let key: any = serverEncrypt.decryptLong(encrypt.encrypt_key)
  key = CryptoJS.enc.Hex.parse(key)

  let decrypt: any = CryptoJS.AES.decrypt(data, key, {
    mode : CryptoJS.mode.ECB,
    padding : CryptoJS.pad.Pkcs7
  });

  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}
