import { configureStore } from '@reduxjs/toolkit'
import tokenReducer from './token'
import accountReducer from './account'
import currentProjectReducer from './currentProject'
import encryptReducer from './encrypt'
import taskReducer from './task'
import createTaskReducer from './createTask';

const store = configureStore({
  reducer: {
    account: accountReducer,
    encrypt: encryptReducer,
    createTask: createTaskReducer,
    currentProject: currentProjectReducer,
    task: taskReducer,
    token: tokenReducer
  }
})

export default store
